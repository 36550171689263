import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/css/bootstrap.min.css'



import './assets/css/font-awesome.min.css'
import './assets/css/magnific-popup.css'
import './assets/css/animate.min.css'
import './assets/css/slick.css'
import './assets/css/default.css'
import './assets/css/style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


reportWebVitals();
