import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import InsidePageBanner from '../Component/InsidePageBanner';
import SEO from '../SeoComponent/Seo';

const faqData = [
    {
        question: "What services does your marketing agency offer?",
        answer: "Our marketing agency offers a wide range of services including SEO, content marketing, social media management, email marketing, and PPC advertising."
    },
    {
        question: "How do you measure the success of a marketing campaign?",
        answer: "We use a variety of metrics to measure the success of a marketing campaign, including website traffic, conversion rates, lead generation, and return on investment (ROI)."
    },
    {
        question: "What is your approach to SEO?",
        answer: "Our approach to SEO involves thorough keyword research, on-page optimization, content creation, link building, and regular performance analysis to ensure your website ranks high on search engines."
    },
    {
        question: "Can you help with social media marketing?",
        answer: "Yes, we offer comprehensive social media marketing services that include strategy development, content creation, posting schedules, and engagement analysis to build your brand's presence on social media."
    },
    {
        question: "Do you offer custom marketing strategies?",
        answer: "Absolutely! We understand that each business is unique, so we tailor our marketing strategies to meet your specific goals and target audience."
    }
];

function Faq() {
    return (
        <>
            <SEO title="FAQ's" description="Welcome to our FAQ's page." />

            <Header />
            <InsidePageBanner title="FAQ's" />
            <section className='my-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="accordion" id="accordionExample">
                                {faqData.map((item, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`heading${index}`}>
                                            <button
                                                className="accordion-button shadow-none"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${index}`}
                                                aria-expanded={index === 0 ? 'true' : 'false'}
                                                aria-controls={`collapse${index}`}
                                            >
                                                {item.question}
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${index}`}
                                            className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                            aria-labelledby={`heading${index}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {item.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Faq;
