import React, { useState, useTransition } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import heroLine from '../assets/images/hero-line.png';
import brand1 from '../assets/images/brand-1.png';
import brand2 from '../assets/images/brand-2.png';
import brand3 from '../assets/images/brand-3.png';
import overview1 from '../assets/images/overview-1.png';
import overview2 from '../assets/images/overview-2.png';
import overview3 from '../assets/images/overview-3.png';
import overview4 from '../assets/images/overview-4.png';
import { Modal } from 'react-bootstrap';

function Overview() {
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.1 // Trigger when 10% of the section is in view
    });
    const [show, setShow] = useState(false)



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <section className="overview-area pb-100" ref={ref}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="overview-content">
                                <img loading="lazy" src={heroLine} alt="" />
                                <span>overview</span>
                                <h3 className="title">About our company insight</h3>
                                <p>Triplet I award-winning interdisciplinary architectural studio cultural, residential and commercial projects built worldwide. We pride ourselves on being builders creating architectural.</p>
                                <a className="main-btn" href="#" onClick={(e) => setShow(true)}>Let’s Chat</a>
                                <div className="brand-item d-flex align-items-center mt-70">
                                    <div className="item">
                                        <a href="#"><img loading="lazy" src={brand1} alt="" /></a>
                                    </div>
                                    <div className="item">
                                        <a href="#"><img loading="lazy" src={brand2} alt="" /></a>
                                    </div>
                                    <div className="item">
                                        <a href="#"><img loading="lazy" src={brand3} alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="overview-counter-area">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="overview-counter-item mt-10 animated wow fadeInUp" data-wow-duration="3000ms" data-wow-delay="0ms">
                                            <div className="icon">
                                                <img loading="lazy" src={overview1} alt="" />
                                            </div>
                                            <h3 className="title count">
                                                {inView ? <CountUp end={63} duration={3} /> : '0'}
                                            </h3>
                                            <span>Years of experience</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="overview-counter-item mt-10 animated wow fadeInUp" data-wow-duration="3000ms" data-wow-delay="300ms">
                                            <div className="icon">
                                                <img loading="lazy" src={overview2} alt="" />
                                            </div>
                                            <h3 className="title count">
                                                {inView ? <CountUp end={79} duration={3} /> : '0'}
                                            </h3>
                                            <span>Project taken</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="overview-counter-item mt-30 animated wow fadeInUp" data-wow-duration="3000ms" data-wow-delay="600ms">
                                            <div className="icon">
                                                <img loading="lazy" src={overview3} alt="" />
                                            </div>
                                            <h3 className="title count">
                                                {inView ? <CountUp end={27} duration={3} /> : '0'}
                                            </h3>
                                            <span>Awards won</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="overview-counter-item mt-30 animated wow fadeInUp" data-wow-duration="3000ms" data-wow-delay="900ms">
                                            <div className="icon">
                                                <img loading="lazy" src={overview4} alt="" />
                                            </div>
                                            <h3 className="title count">
                                                {inView ? <CountUp end={32} duration={3} suffix="k" /> : '0'}
                                            </h3>
                                            <span>Twitter followers</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default Overview;
