import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseURL } from '../Api/BaseUrl'

function ServiceCard(props) {
    const navigate = useNavigate()
    return (
        <>
            {props.data && props.data.map((item) => {
                return (
                    <>
                        <div class="col-lg-4 col-md-6 col-sm-8">
                            <div class={`service-item  h-100 animated wow text-center fadeInUp ${props.class}`} onClick={(e) => navigate(`/service-detail/${props.detail}/${item.url}`)}
                                data-wow-duration="1000ms" data-wow-delay="0ms">
                                <div class="icon">

                                    <img loading="lazy" src={`${BaseURL}${item.image}`} className='img-fluid rounded-4' style={{ width: "100%" }} alt="" />
                                </div>
                                <h3 class="title mt-3">{item.title}</h3>
                                {item.paragraph && <p>{item.paragraph}</p>}
                            </div>
                        </div>
                    </>
                )
            })}

        </>
    )
}

export default ServiceCard
