import React from 'react';
import heroLine from '../assets/images/hero-line.png';
import portfolioItem1 from '../assets/images/portfolio/portfolio-item-1.png';
import portfolioItem2 from '../assets/images/portfolio/portfolio-item-2.png';
import portfolioItem3 from '../assets/images/portfolio/portfolio-item-3.png';
import portfolioItem4 from '../assets/images/portfolio/portfolio-item-4.png';
import portfolioItem5 from '../assets/images/portfolio/portfolio-item-5.png';
import portfolioItem6 from '../assets/images/portfolio/portfolio-item-6.png';
import portfolioItem7 from '../assets/images/portfolio/portfolio-item-7.png';
import portfolioItem8 from '../assets/images/portfolio/portfolio-item-8.png';
import portfolioItem9 from '../assets/images/portfolio/portfolio-item-9.png';
import projectLine from '../assets/images/project-line.png';
import portfolioArrow from '../assets/images/icon/portfolio-arrow.png';

const OurProject = () => {
    return (
        <div className="portfolio-style-2">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-9">
                        <div className="section-title text-center">
                            <img loading="lazy" src={heroLine} alt="Hero Line" />
                            <h3 className="title">Our Project</h3>
                            <p>Our focus is on delivering you the absolute best support guiding you through training and providing.</p>
                        </div>
                    </div>
                </div>
                <div className="row grid-2">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 grid-item">
                                <div className="portfolio-style-2-item mt-30">
                                    <img loading="lazy" src={portfolioItem1} alt="Portfolio Item 1" />
                                    <div className="project-overlay">
                                        <img loading="lazy" src={projectLine} alt="Project Line" />
                                        <h4 className="title">Digital</h4>
                                        <span>Illustration</span>
                                        <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 grid-item">
                                <div className="portfolio-style-2-item mt-30">
                                    <img loading="lazy" src={portfolioItem2} alt="Portfolio Item 2" />
                                    <div className="project-overlay">
                                        <img loading="lazy" src={projectLine} alt="Project Line" />
                                        <h4 className="title">Digital</h4>
                                        <span>Illustration</span>
                                        <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 grid-item">
                                <div className="portfolio-style-2-item mt-30">
                                    <img loading="lazy" src={portfolioItem3} alt="Portfolio Item 3" />
                                    <div className="project-overlay">
                                        <img loading="lazy" src={projectLine} alt="Project Line" />
                                        <h4 className="title">Digital artist design</h4>
                                        <span>Illustration</span>
                                        <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-lg-12 grid-item">
                                <div className="portfolio-style-2-item mt-30">
                                    <img loading="lazy" src={portfolioItem4} alt="Portfolio Item 4" />
                                    <div className="project-overlay">
                                        <img loading="lazy" src={projectLine} alt="Project Line" />
                                        <h4 className="title">Digital artist design</h4>
                                        <span>Illustration</span>
                                        <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 grid-item">
                                <div className="portfolio-style-2-item mt-30">
                                    <img loading="lazy" src={portfolioItem5} alt="Portfolio Item 5" />
                                    <div className="project-overlay">
                                        <img loading="lazy" src={projectLine} alt="Project Line" />
                                        <h4 className="title">Digital</h4>
                                        <span>Illustration</span>
                                        <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 grid-item">
                                <div className="portfolio-style-2-item mt-30">
                                    <img loading="lazy" src={portfolioItem6} alt="Portfolio Item 6" />
                                    <div className="project-overlay">
                                        <img loading="lazy" src={projectLine} alt="Project Line" />
                                        <h4 className="title">Digital</h4>
                                        <span>Illustration</span>
                                        <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-3 grid-item">
                        <div className="portfolio-style-2-item mt-30">
                            <img loading="lazy" src={portfolioItem7} alt="Portfolio Item 7" />
                            <div className="project-overlay">
                                <img loading="lazy" src={projectLine} alt="Project Line" />
                                <h4 className="title">Digital</h4>
                                <span>Illustration</span>
                                <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 grid-item">
                        <div className="portfolio-style-2-item item-8 mt-30">
                            <img loading="lazy" src={portfolioItem8} alt="Portfolio Item 8" />
                            <div className="project-overlay">
                                <img loading="lazy" src={projectLine} alt="Project Line" />
                                <h4 className="title">Digital artist design</h4>
                                <span>Illustration</span>
                                <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 grid-item">
                        <div className="portfolio-style-2-item mt-30">
                            <img loading="lazy" src={portfolioItem9} alt="Portfolio Item 9" />
                            <div className="project-overlay">
                                <img loading="lazy" src={projectLine} alt="Project Line" />
                                <h4 className="title">Digital</h4>
                                <span>Illustration</span>
                                <a href="#"><img loading="lazy" src={portfolioArrow} alt="Portfolio Arrow" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurProject;
