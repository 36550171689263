import React from 'react';
import Slider from 'react-slick';

import testimonialInfo from '../assets/images/testimonial-info.png';

const InsideTestimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <section className="testimonial-2-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-title-3">
                            <span>Testimonial</span>
                            <h3 className="title">What our clients say about our agency.</h3>
                        </div>
                        <div className="testimonial-2-active mt-100">
                            <Slider {...settings}>
                                <div className="item">
                                    <span>Product Design</span>
                                    <p>“Sitting proudly atop is the two-storey penthouse. The former master bedroom phenomenally spacious makes the most of its unrivalled position.”</p>
                                    <div className="info mt-30">
                                        <img loading="lazy"   src={testimonialInfo} alt="" />
                                        <h5>Quomodo Theme</h5>
                                        <span>Product Manager</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Product Design</span>
                                    <p>“Sitting proudly atop is the two-storey penthouse. The former master bedroom phenomenally spacious makes the most of its unrivalled position.”</p>
                                    <div className="info mt-30">
                                        <img loading="lazy"   src={testimonialInfo} alt="" />
                                        <h5>Quomodo Theme</h5>
                                        <span>Product Manager</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Product Design</span>
                                    <p>“Sitting proudly atop is the two-storey penthouse. The former master bedroom phenomenally spacious makes the most of its unrivalled position.”</p>
                                    <div className="info mt-30">
                                        <img loading="lazy"   src={testimonialInfo} alt="" />
                                        <h5>Quomodo Theme</h5>
                                        <span>Product Manager</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Product Design</span>
                                    <p>“Sitting proudly atop is the two-storey penthouse. The former master bedroom phenomenally spacious makes the most of its unrivalled position.”</p>
                                    <div className="info mt-30">
                                        <img loading="lazy"   src={testimonialInfo} alt="" />
                                        <h5>Quomodo Theme</h5>
                                        <span>Product Manager</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Product Design</span>
                                    <p>“Sitting proudly atop is the two-storey penthouse. The former master bedroom phenomenally spacious makes the most of its unrivalled position.”</p>
                                    <div className="info mt-30">
                                        <img loading="lazy"   src={testimonialInfo} alt="" />
                                        <h5>Quomodo Theme</h5>
                                        <span>Product Manager</span>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InsideTestimonial;
