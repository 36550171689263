import axios from "axios"
import { BaseURL } from "./BaseUrl"



export const postapi = async (endpoint, requestdata) => {
    try {
        const res = await axios.post(`${BaseURL}${endpoint}`, requestdata)
        return res
    } catch (err) {
        console.log(err)
    }
}


export const getapi = async (endpoint) => {
    try {
        const res = await axios.get(`${BaseURL}${endpoint}`)
        return res
    } catch (err) {
        console.log(err)
    }
}