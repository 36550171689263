import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'

import pageInfoImage from '../assets/images/page-info.png';
// Example images import, update with actual paths if needed
import postIcon1 from '../assets/images/icon/post-icon-1.png';
import postIcon2 from '../assets/images/icon/post-icon-2.png';
import postThumb1 from '../assets/images/post-thumb-1.png';
import postThumb2 from '../assets/images/post-thumb-2.png';
import pageInfo from '../assets/images/page-info.png';
import postArrow from '../assets/images/post-arrow.png';
import heroIcon2 from '../assets/images/icon/hero-icon-2.png';
import popularPost1 from '../assets/images/popular-post-1.png';
import popularPost2 from '../assets/images/popular-post-2.png';
import popularPost3 from '../assets/images/popular-post-3.png';
import popularPost4 from '../assets/images/popular-post-4.png';
import popularPost5 from '../assets/images/popular-post-5.png';
// import gallery1 from '../assets/images/gallery-1.png';
// import gallery2 from '../assets/images/gallery-2.png';
// import gallery3 from '../assets/images/gallery-3.png';
// import gallery4 from '../assets/images/gallery-4.png';
// import gallery5 from '../assets/images/gallery-5.png';
// import gallery6 from '../assets/images/gallery-6.png';
// import gallery7 from '../assets/images/gallery-7.png';
// import gallery8 from '../assets/images/gallery-8.png';
// import gallery9 from '../assets/images/gallery-9.png';
// import footerNewsletterDot from '../assets/images/icon/footer-newsletter-dot.png';
// import article1 from '../assets/images/article-1.jpg';
// import article2 from '../assets/images/article-2.jpg';
// import article3 from '../assets/images/article-3.jpg';
import { Link, useParams } from 'react-router-dom';
import SEO from '../SeoComponent/Seo';
import { getapi } from '../Api/Api';
import { BaseURL } from '../Api/BaseUrl';
import moment from 'moment';
import InsidePageBanner from '../Component/InsidePageBanner';
import Footer from '../Layout/Footer';


function SingleArticle() {
    const { url } = useParams()
    const [data, setdata] = useState([])
    const [articles, setarticles] = useState([])


    const handleget = async () => {
        let res = await getapi(`blogs/${url}`)
        console.log(res.data.data)
        setdata(res.data.data)
    }

    console.log(data)

    const handleallarticles = async () => {
        let res = await getapi(`published-blogs`)
        console.log(res.data.data)
        setarticles(res.data.data)
    }

    useEffect(() => {
        handleget()
        handleallarticles()
    }, [url])
    // const articles = [
    //     {
    //         id: 1,
    //         title: 'The Clear Difference Between Cold Brew & Iced Coffee',
    //         date: '25 ',
    //         month: "JUN",
    //         imgSrc: article1,
    //         description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
    //         link: '#',
    //     },
    //     {
    //         id: 2,
    //         title: 'Your Small Business Success From Marketing',
    //         date: '25 ',
    //         month: "JUN",
    //         imgSrc: article2,
    //         description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
    //         link: '#',
    //     },
    //     {
    //         id: 3,
    //         title: 'The Start-Up Ultimate Guide Make Wordpress Journal.',
    //         date: '25 ',
    //         month: "JUN",
    //         imgSrc: article3,
    //         description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
    //         link: '#',
    //     },

    // ];
    return (
        <>
            <SEO title={data.title} description={data?.meta_description} />
            <Header />
            <InsidePageBanner title="Article Detail" />
            <section className="single-post-area pt-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="single-post-content mt-30">
                                <div className="single-post-topbar">
                                    <ul>
                                        <li><img loading="lazy" src={postIcon1} alt="Date" />{moment(data.publish_date).format("MMMM-DD-YYYY")}</li>
                                        {data.blog_type && <li><img loading="lazy" className='' src={postIcon2} alt="Categories" /> {data.blog_type}</li>}
                                        {data.category && <li> {data.category}</li>}
                                        {data.schema && <li> {data.schema}</li>}
                                    </ul>

                                    {/* <div className="social">
                                        <ul>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                            <li><a href="#"><i class="fal fa-bookmark"></i></a></li>
                                            <li><a href="#"><i class="fas fa-ellipsis-v"></i></a></li>
                                        </ul>
                                    </div> */}
                                </div>

                                <div className="post-content mt-3">
                                    <img loading="lazy" src={`${BaseURL}${data.image}`} className='img-fluid rounded-4 mb-3 w-100 img-fluid' alt="" />
                                    <div className="">
                                        <h4>{data?.title}</h4>
                                        <h6 className='text-secondary'>{data?.short_detail}</h6>
                                    </div>
                                    <div className="" dangerouslySetInnerHTML={{ __html: data.detail }}></div>
                                    <h6>Author : <span className='text-secondary'>{data.author}</span></h6>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-8">
                            <div className="single-post-sidebar mt-30">

                                <div className="sidebar-post mt-30">
                                    <h4 className="title">Popular post</h4>
                                    <div className="post-items">
                                        {articles.slice(0, 2).map((item, index) => (
                                            <Link to={`/article-detail/${item.url}`}>
                                                <div className="item mt-30" key={index}>
                                                    <div className="thumb">
                                                        <img loading="lazy" src={`${BaseURL}${item.image}`} className='rounded-3' style={{ width: "100px" }} alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <a href="#">
                                                            <h5 className="title">{item.title}</h5>
                                                        </a>
                                                        <span>{moment(item.createdAt).format("MMMM-DD-YYYY")}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                        {articles.length > 2 && <Link to={'/allarticles'} className='btn w-100 bg-primary mt-3 text-white'>
                                            Veiw All</Link>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="article-post-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="article-post-title text-center">
                                <h3 class="title">Related Articles</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        {articles.slice(0, 6).map((item) => {
                            return (
                                <>
                                    <div class="col-lg-4 col-md-6 col-sm-9">
                                        <div class="article-2-item mt-30">
                                            <div class="article-thumb">
                                                <img loading="lazy" src={`${BaseURL}${item.image}`} style={{ height: "200px" }} alt="" />
                                            </div>
                                            <div class="article-content">
                                                {item.blog_type && <span className='blogtype'>{item.blog_type}</span>}
                                                <div class="date">
                                                    <div class="item">
                                                        <h5 class="title">{moment(item.publish_date).format("MMMM-DD-YYYY")}</h5>
                                                        {/* <span>{item.month}</span> */}
                                                    </div>

                                                </div>
                                                <h3 class="title"><Link to={`/article-detail/${item.url}`}>{item.title}.</Link></h3>
                                                <Link to={`/article-detail/${item.url}`}>
                                                    Learn more
                                                    <span class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.196" height="12.663" viewBox="0 0 10.196 12.663">
                                                            <path data-name="Path 18155" d="M32.324,974.539,28.2,978.448a.731.731,0,0,0-.052.947.678.678,0,0,0,.948,0l3.027-2.864v9.346a.652.652,0,1,0,1.3,0v-9.346l3.027,2.864a.664.664,0,0,0,.948,0,.714.714,0,0,0-.052-.947l-4.126-3.909A.661.661,0,0,0,32.324,974.539Z" transform="translate(-27.676 -974.112)" fill="#fff" stroke="#fff" stroke-width="0.5"></path>
                                                        </svg>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>

            <Footer />


        </>
    )
}

export default SingleArticle














