import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

// import portfolioDetailsThumb from '../assets/images/portfolio-details-thumb.png';
// import portfolioDetailsThumb1 from '../assets/images/portfolio-details-thumb-1.png';
// import portfolioDetailsThumb2 from '../assets/images/portfolio-details-thumb-2.png';
// import planing1 from '../assets/images/icon/planing-1.png';
// import planing2 from '../assets/images/icon/planing-2.png';
// import planing3 from '../assets/images/icon/planing-3.png';
// import planing4 from '../assets/images/icon/planing-4.png';
// import planing5 from '../assets/images/icon/planing-5.png';
// import postNews2 from '../assets/images/post-news-2.png';
import { Modal } from 'react-bootstrap';
import Iframe from 'react-iframe';
import SEO from '../SeoComponent/Seo';
import { getapi } from '../Api/Api';
import { useParams } from 'react-router-dom';

import Preloader from '../Component/PreLoder';
import hero from "../assets/images/hero-bg.jpg"
import { BaseURL } from '../Api/BaseUrl';
import InsidePageBanner from '../Component/InsidePageBanner';

function PortfolioDetail() {


    const [show, setShow] = useState(false);
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])
    const { unique } = useParams()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleget = async () => {
        let res = await getapi(`subportfolio/${unique}`)


        if (res?.data?.error == '0') {
            setloading(false)
            setdata(res?.data?.data)

            console.log(data)
        } else {
            setloading(false)
        }
    }

    useEffect(() => {
        handleget()
    }, [])

    const [banner, setbanner] = useState([])

    const handlegetbanner = async () => {


        let res = await getapi(`bannertitle/Portfolio`)
        setbanner(res?.data?.data)


    }

    useEffect(() => {
        handlegetbanner()
    }, [])


    function getVideoId(url) {
        const regex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)|(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regex);
        return match ? match[1] || match[2] : null;
    }



    return (
        <>
            {loading && <Preloader />}
            <SEO title="Portfolio Detail" description="Welcome to our Portfolio Detail page." />
            <Header />
            {/* <div className="page-title-area page-title-2-area">
                <div className="section__bg">
                    <img loading="lazy" src={`${BaseURL}${banner?.image}`} alt="" className='w-100 ' style={{ height: "448px", objectFit: "cover" }} onError={(e) => { e.target.onerror = null; e.target.src = hero }} />

                </div>
              
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="page-title-content page-title-content-2 text-center">
                                <h3 className="title">Portfolio Detail</h3>

                                <nav className="pb-15" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Portfolio</li>
                                        <li className="breadcrumb-item active" aria-current="page">Details</li>
                                    </ol>
                                </nav>
                                <div className="scroll-to-down">
                                    <a href="#"><i className="fal fa-arrow-down"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

<InsidePageBanner title="Portfolio Detail"/>

            <section className="portfolio-details-area mt-4">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <div className="col-lg-12">
                            <div className="portfolio-details-top">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="portfolio-details-top-title mt-30">
                                            <h3 className="title">Everything you need to grow your business Order today!</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="portfolio-details-top-text mt-30">
                                            <p>Ventilators will be taken from certain New York hospitals and redistributed to the worst-hit parts of the state under an order to be signed by Governor Andrew Cuomo. New York saw its highest single-day increase in deaths, up by 562 to 2,935 - nearly half of all virus-related US deaths recorded yesterday.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-details-category">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="portfolio-details-category-thumb mt-30">
                                            <img loading="lazy"   src={portfolioDetailsThumb} alt="Portfolio Details Thumb" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="portfolio-details-category-list mt-30">
                                            <div className="item">
                                                <span>Client:</span>
                                                <h4 className="title">Seative Digital</h4>
                                            </div>
                                            <div className="item">
                                                <span>Services:</span>
                                                <h4 className="title">App Design, Development A/B Testing</h4>
                                            </div>
                                            <div className="item">
                                                <span>Team:</span>
                                                <h4 className="title">Product Design and Development</h4>
                                            </div>
                                            <div className="item">
                                                <span>Category:</span>
                                                <h4 className="title">Digital Product</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-details-content mt-65">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="text">
                                            <p>New York saw its highest single-day increase in deaths, up 562 to 2,935 - nearly half of all virus-related US deaths recorded yesterday. The White House may advise those in virus hotspots to wear face coverings in public to help stem the spread mid deepening crisis, top health official Dr Anthony Fauci has said he believes all states should issue stay-at-home orders.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="text">
                                            <p>I don’t understand why that’s not happening,” Dr Fauci told CNN on Thursday. “If you look at what’s going on in this country, I just don’t understand why we’re not doing that.”</p>
                                            <div className="text pt-15">
                                                <p>You’ve got to put your foot on the accelerator to bring that number down,” he added, referring to infection and death rates.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-business-planing pt-80 pb-60">
                                <h3 className="title">Business planning</h3>
                                <div className="business-planing-items d-flex justify-content-center justify-content-lg-between align-items-center">
                                    <div className="item mt-65">
                                        <img loading="lazy"   src={planing1} alt="Business Strategy" />
                                        <span>Business Strategy</span>
                                    </div>
                                    <div className="item mt-65">
                                        <img loading="lazy"   src={planing2} alt="UX & Product Design" />
                                        <span>UX & Product Design</span>
                                    </div>
                                    <div className="item mt-65">
                                        <img loading="lazy"   src={planing3} alt="App Development" />
                                        <span>App Development</span>
                                    </div>
                                    <div className="item mt-65">
                                        <img loading="lazy"   src={planing4} alt="Marketing Strategy" />
                                        <span>Marketing Strategy</span>
                                    </div>
                                    <div className="item mt-65">
                                        <img loading="lazy"   src={planing5} alt="Digital Marketing" />
                                        <span>Digital Marketing</span>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-details-thumb">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="portfolio-details-thumb-1">
                                            <img loading="lazy"   src={portfolioDetailsThumb1} alt="Portfolio Details Thumb 1" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="portfolio-details-thumb-2">
                                            <img loading="lazy"   src={portfolioDetailsThumb2} alt="Portfolio Details Thumb 2" />
                                            <a className="video-popup" onClick={(e) => setShow(true)}><i className="fas fa-play"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-details-content mt-65">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="text">
                                            <p>New York saw its highest single-day increase in deaths, up 562 to 2,935 - nearly half of all virus-related US deaths recorded yesterday. The White House may advise those in virus hotspots to wear face coverings in public to help stem the spread mid deepening crisis, top health official Dr Anthony Fauci has said he believes all states should issue stay-at-home orders.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="text">
                                            <ul>
                                                <li>Should more of us wear face masks?</li>
                                                <li>Why some countries wear face masks and others don’t</li>
                                                <li>Coronavirus: Are homemade face masks safe?</li>
                                                <li>Should Subash theme cool more of us wear face masks?</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-details-social pt-85">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="quote">
                                            <div className="text pb-15">
                                                <p>I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.</p>
                                            </div>
                                            <div className="info">
                                                <img loading="lazy"   src={postNews2} alt="Subash Chandra" />
                                                <h5 className="title">Subash Chandra</h5>
                                                <span>Founder at Seative Digital</span>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="portfolio-details-content mt-65">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="text">
                                            <p>New York saw its highest single-day increase in deaths, up 562 to 2,935 - nearly half of all virus-related US deaths recorded yesterday. The White House may advise those in virus hotspots to wear face coverings in public to help stem the spread mid deepening crisis, top health official Dr Anthony Fauci has said he believes all states should issue stay-at-home orders.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="text">
                                            <p>I don’t understand why that’s not happening,” Dr Fauci told CNN on Thursday. “If you look at what’s going on in this country, I just don’t understand why we’re not doing that.”</p>
                                            <div className="text pt-15">
                                                <p>You’ve got to put your foot on the accelerator to bring that number down,” he added, referring to infection and death rates.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         
                        </div> */}
                        <div className={"col-md-11"}>
                            {data?.video_link && (
                                <iframe
                                    width="100%"
                                    height="415"
                                    src={`${data.video_link}?autoplay=1&loop=1&playlist=${getVideoId(data.video_link)}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            )}
                            {data?.title && <h3 className="title fs-1">{data?.title}</h3>}

                            {data?.subtitle && <p className=''>{data?.subtitle}</p>}
                            {data?.boxtitle && <h5>{data?.boxtitle}</h5>}


                            <div style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: data?.detail }}></div>
                        </div>
                        <div className="col-md-11">
                            <h3>Gallery</h3>
                            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-4">
                                {data.image &&
                                    data.image.map((item, index) => (
                                        <div className="col" key={index}>
                                            <img
                                                loading='lazy'
                                                src={`${BaseURL}${item?.img}`}
                                                className="img-fluid rounded-4 subportfolioimage"
                                                alt=""
                                                style={{ height: "250px", cursor: "pointer", objectFit: "cover" }}
                                                data-bs-toggle="modal"
                                                data-bs-target={`#imageModal${index}`}
                                            />

                                            {/* Modal */}
                                            <div
                                                className="modal fade "
                                                id={`imageModal${index}`}
                                                tabIndex="-1"
                                                aria-labelledby="imageModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog modal-md modal-dialog-centered">
                                                    <div className="modal-content" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(10px)' }}>
                                                        <div className="modal-header border-0">
                                                            <button
                                                                type="button"
                                                                className="btn-close"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                            ></button>
                                                        </div>
                                                        <div className="modal-body text-center p-3">
                                                            <img
                                                                src={`${BaseURL}${item?.img}`}
                                                                className="img-fluid rounded-4"
                                                                alt=""

                                                                style={{ width: "300px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <Footer />

            <Modal
                size="lg"
                centered
                show={show}
                className="productVideo"
                onHide={handleClose}
                backdrop="animation"
                keyboard={false}
            >
                <Modal.Header closeButton className="p-0 border-0">
                    <button className='close-btn btn d-none' style={{ opacity: 0 }} onClick={(e) => setShow(false)}><i class="fas fa-times"></i></button>
                </Modal.Header>

                <Modal.Body>
                    <Iframe
                        url={"https://www.youtube.com/embed/Rgm0t4viB14?si=atiLLBTlXDSpQ7Ae"}
                        id=""
                        className="iframevideo w-100"

                        display="block"
                        position="relative"
                        allow="fullscreen"
                        allowFullScreen
                    />

                </Modal.Body>
            </Modal>
        </>
    )
}

export default PortfolioDetail






