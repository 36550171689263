import React, { useEffect, useState } from "react";

const TYPING_SPEED = 150;
const DELETING_SPEED = 20;

const Typer = ({ dataText }) => {
    const [text, setText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(TYPING_SPEED);

    useEffect(() => {
        let typingTimeout;

        const handleType = () => {
            const i = loopNum % dataText.length;
            const fullText = dataText[i];

            setText((prevText) => {
                if (isDeleting) {
                    return fullText.substring(0, prevText.length - 1);
                } else {
                    return fullText.substring(0, prevText.length + 1);
                }
            });

            if (!isDeleting && text === fullText) {
                setTimeout(() => {
                    setTypingSpeed(DELETING_SPEED);
                    setIsDeleting(true);
                }, 2000);
                return () => { clearTimeout() }
            } else if (isDeleting && text === "") {
                setTypingSpeed(TYPING_SPEED);
                setIsDeleting(false);
                setLoopNum((prevLoopNum) => prevLoopNum + 1);
            }
        };

        typingTimeout = setTimeout(() => {
            handleType();
        }, typingSpeed);

        return () => {
            clearTimeout(typingTimeout);
        };
    }, [loopNum, text, isDeleting, typingSpeed, dataText]);

    return (
        <h2>
            <span className="color_1">{text}</span>
            <span id="cursor"></span>
        </h2>
    );
};

export default Typer;
