import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Footer from '../../Layout/Footer'
import InsidePageBanner from '../../Component/InsidePageBanner'
import Header from '../../Layout/Header'
import heroline from "../../assets/images/hero-line.png"
import SEO from '../../SeoComponent/Seo'
import postArrow from '../../assets/images/post-arrow.png';
import heroIcon2 from '../../assets/images/icon/hero-icon-2.png';
import { getapi } from '../../Api/Api'
import logo from "../../assets/images/logo.png"


import Preloader from '../../Component/PreLoder'
import { BaseURL } from '../../Api/BaseUrl'
import Card from '../../Component/Card'
import Slider from 'react-slick'

function Subdetail() {
    const { detail, subdetail } = useParams()
    const [data, setdata] = useState([])
    const [restdata, setrestdata] = useState([])
    const [loading, setloading] = useState(false)
    const [subcard, setsubcard] = useState([])
    const [editorData, setEditorData] = useState('<p>Initial content</p>');



    const settings = {
        dots: false,

        infinite: true,
        speed: 500,

        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 3000, // Set autoplay speed to 3000 milliseconds
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    useEffect(() => {
        // Optional: Initialize editor data or fetch data from an API
        // setEditorData('<p>Initial content</p>');
    }, []);

    const handleEditorChange = (event) => {
        setEditorData(event.editor.getData());
    };

    const handleget = async () => {
        setloading(true)
        let res = await getapi(`subs?url=${subdetail}`)


        if (res?.data?.error == 0) {
            setloading(false)
            setdata(res?.data?.data)
        } else {
            setloading(false)
        }
    }


    const getdata = async () => {
        setloading(true)
        let res = await getapi(`getbyurl?url=${detail}`)
        console.log("mainservice", res.data)
        if (res?.data?.error == 0) {
            setloading(false)
            setrestdata(res?.data?.data)
        } else {
            setloading(false)
        }
    }
    useEffect(() => {
        getdata()
    }, [])



    const handlesubcard = async () => {
        setloading(true)
        let res = await getapi(`cardbyname?name=${subdetail}`)


        if (res?.data?.error == 0) {
            setloading(false)

            setsubcard(res?.data?.data)
            console.log("servicecard", res.data.data)
        } else {
            setloading(false)
        }
    }

    useEffect(() => {
        handleget()
        handlesubcard()
    }, [subdetail])


    function getVideoId(url) {
        const regex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)|(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regex);
        return match ? match[1] || match[2] : null;
    }

    return (
        <>
            {loading && <Preloader />}
            <SEO title={data?.seotitle} description={data?.seodesc} keywords={data?.keyword} />
            <Header />
            <InsidePageBanner title={`${detail} `} />

            <section>
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="section-title text-center mt-4">
                                <img loading="lazy" src={heroline} alt="Hero Line" />
                                <h3 className="title text-uppercase">{data.title} </h3>

                                {data.link && (
                                    <div className="">
                                        {data.link != "undefined" &&
                                            <iframe
                                                width="100%"
                                                height="415"
                                                src={`${data.link}?autoplay=1&loop=1&playlist=${getVideoId(data.link)}`}
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                            ></iframe>
                                        }
                                    </div>
                                )}
                                {/* <p dangerouslySetInnerHTML={{ __html: data.detail }}></p> */}
                                <p>{data.shortdetail}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className=' my-5 subservicecardsection'>
                <div className="container ">

                    {subcard.map((item, index) => {
                        return (
                            <div className="row align-items-center mb-4" key={item._id}>
                                {index % 2 === 0 ? (
                                    <>
                                        <div className="col-md-6 subdetailimage">
                                            <Slider {...settings}>
                                                {item.image && item.image.map((item) => {
                                                    return (
                                                        <>
                                                            <div>
                                                                <div className={item.img ? '' : 'bg-secondary h-200'}>
                                                                    <img
                                                                        src={`${BaseURL}${item.img}` || logo}
                                                                        className="d-block w-100 rounded-4"
                                                                        alt="Slide 1"
                                                                        onError={(e) => { e.target.src = logo }}
                                                                    />
                                                                </div>

                                                            </div>


                                                        </>
                                                    )
                                                })}


                                            </Slider>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className='mb-1'>{item.name} </h3>
                                            {item.subtitle && item.subtitle !== "undefined" && <h5 className='text-blue'>{item.subtitle}</h5>}


                                            <p className='text-wrap breakword'>{item.detail}</p>
                                            <ul style={{ listStyle: "none" }} className='m-0 p-0'>
                                                {item.list.map((list) => {
                                                    return (
                                                        <>
                                                            <li>{list.title}</li>
                                                        </>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-md-6  order-md-1 order-2">
                                            <h3 className='mb-1'>{item.name} </h3>
                                            {item.subtitle && item.subtitle !== "undefined" && <h5 className='text-blue'>{item.subtitle}</h5>}
                                            <p className='text-wrap breakword'>{item.detail}</p>
                                            <ul style={{ listStyle: "none" }} className='m-0 p-0'>
                                                {item.list.map((list) => {
                                                    return (
                                                        <>
                                                            <li>{list.title}</li>
                                                        </>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="col-md-6 subdetailimage order-md-2 order-1">
                                            <Slider {...settings}>
                                                {item.image && item.image.map((item) => {
                                                    return (
                                                        <>
                                                            <div>
                                                                <div className={item.img ? '' : 'bg-secondary h-200'}>
                                                                    <img
                                                                        src={`${BaseURL}${item.img}` || logo}
                                                                        className="d-block w-100 rounded-4"
                                                                        alt="Slide 1"
                                                                        onError={(e) => { e.target.src = logo }}
                                                                    />
                                                                </div>

                                                            </div>


                                                        </>
                                                    )
                                                })}


                                            </Slider>
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}

                </div>
            </section>

            <Footer />
        </>
    )
}

export default Subdetail



const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', right: "10px", zIndex: "99", top: "50%" }}
            onClick={onClick}
        />
    );
}

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', left: "10px", zIndex: "99", top: "50%" }}
            onClick={onClick}
        />
    );
}




