
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Iframe from 'react-iframe';
import imgframe from "../assets/images/frame.png"
import mainvideo from "../assets/images/videoplayback.mp4"
import { getapi } from '../Api/Api';
import { BaseURL } from '../Api/BaseUrl';


const Banner = () => {
    const [show, setShow] = useState(false);

    const [banner, setbanner] = useState([])

    const [isMuted, setIsMuted] = useState(true);
    const videoRef = useRef(null);

    const toggleMute = () => {
        setIsMuted(!isMuted);
        if (videoRef.current) {
            videoRef.current.muted = !isMuted;
        }
    };
    const handlebanner = async () => {
        let res = await getapi(`bannertitle/Home`)

        setbanner(res.data.data)
    }

    useEffect(() => {
        handlebanner()
    }, [])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const handleIframeLoad = () => {
                iframe.contentWindow.postMessage(JSON.stringify({
                    event: 'command',
                    func: 'mute',
                    args: []
                }), '*');
            };

            iframe.addEventListener('load', handleIframeLoad);

            return () => {
                iframe.removeEventListener('load', handleIframeLoad);
            };
        }
    }, []);

    const handleUnmute = () => {
        const iframe = iframeRef.current;

        if (iframe) {
            iframe.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'unMute',
                args: []
            }), '*');
        }
    };




    return (
        <>
            <section className="hero-area bannerarea">
                <div className="section__bg">
                    {/* <img loading="lazy"   src={`${BaseURL}${banner.image}`} alt="" className='w-100 ' /> */}
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="hero-content">
                                <img loading="lazy" src="assets/images/hero-line.png" alt="" />
                                <h1 className="title animated wow fadeInLeft" data-wow-duration="3000ms" data-wow-delay="0ms">Global Leader in Modern Business Innovation</h1>
                                <span className="animated wow fadeInLeft" data-wow-duration="3000ms" data-wow-delay="300ms">With unique business passionate people behind.</span>
                                <a className="main-btn main-btn-2 animated wow fadeInDown" data-wow-duration="3000ms" data-wow-delay="600ms" href="#" onClick={(e) => setShow(true)}>Watch Video</a>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="hero-play w-100">
                                <div className='imgframe'>
                                </div>
                                <div onClick={handleUnmute} className='w-100'>
                                    <Iframe
                                        url={banner.link}
                                        className="iframevideo w-100 rounded-5"
                                        display="block"
                                        position="relative"
                                        allow="autoplay; fullscreen"
                                        allowFullScreen
                                        ref={iframeRef}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                size="lg"
                centered
                show={show}
                className="productVideo"
                onHide={handleClose}
                backdrop="animation"
                keyboard={false}
            >
                <Modal.Header closeButton className="p-0 border-0">
                    <button className='close-btn btn d-none' style={{ opacity: 0 }} onClick={(e) => setShow(false)}><i class="fas fa-times"></i></button>
                </Modal.Header>

                <Modal.Body>
                    <Iframe
                        url={banner.link}
                        id=""
                        className="iframevideo w-100"

                        display="block"
                        position="relative"
                        allow="fullscreen"
                        allowFullScreen
                    />

                </Modal.Body>
            </Modal>


            <section class="space position-relative  videosection">

                <div className="myVideo w-100">
                    <video src={`${BaseURL}${banner.image}`} muted={isMuted} autoPlay loop className='w-100 h-100'  ></video>
                </div>

                <button
                    onClick={toggleMute}
                    className="btn bg-dark shadow-none text-white mutedbtn"
                >
                    {isMuted ? <i class="fa-solid fa-volume-xmark"></i> : <i class="fa-solid fa-volume-high"></i>}
                </button>

            </section>


        </>
    );
}

export default Banner;

