import React from 'react'
import { BaseURL } from '../Api/BaseUrl'

function Card(props) {
    return (
        <>
            {props.data && props.data.map((item) => {
                return (
                    <>
                        <div className="">
                            <div className="sub-item text-center">
                                <div className="icon">
                                    <img loading="lazy" src={item.icon ?? `${BaseURL}${item.image}`} className='img-fluid' style={{ opacity: "0", width: "30px" }} alt="" />
                                </div>
                                <h3 className="title text-wrap">{item.title ?? item.name}</h3>
                                <span className='text-white'>{item.para ?? item.detail}</span>
                                {item.para2 && <span className='text-white'>{item.para2}</span>}
                            </div>
                        </div>
                    </>
                )
            })}
        </>
    )
}

export default Card
