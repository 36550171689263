import logo from './logo.svg';
import './App.css';

import Home from './Page/Home';
import Preloader from './Component/PreLoder';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Pricing from './Page/Pricing';
import Contact from './Page/Contact';
import AllArticles from './Page/AllArticles';
import SingleArticle from './Page/SingleArticle';
import PortfolioDetail from './Page/PortfolioDetail';
import Carrer from './Page/Carrer';
import Servicedetail from './Page/ServiceDetailPage/Servicedetail';
import Jobdetail from './Page/Jobdetail';
import PrivacyPolicy from './Page/PrivacyPolicy';
import Faq from './Page/Faq';
import Subdetail from './Page/ServiceDetailPage/Subdetail';
import About from './Page/AboutPage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import PageNotFound from './Page/PageNotFound';
import OurVenture from './Page/OurVenture';


function App() {
  return (
    <>
      <ToastContainer />
      <Preloader />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/allarticles' element={<AllArticles />} />
          <Route path='/career' element={<Carrer />} />

          <Route path='/article-detail/:url' element={<SingleArticle />} />

          <Route path='/portfolio-detail/:unique' element={<PortfolioDetail />} />
          <Route path='/service-detail/:unique' element={<Servicedetail />} />
          <Route path='/service-detail/:detail/:subdetail' element={<Subdetail />} />
          <Route path='/job-detail/:unique' element={<Jobdetail />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/about' element={<About />} />


          <Route path='/venture/:unique' element={<OurVenture />} />



          <Route path='*' element={<PageNotFound />} />











        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
