import React, { useState, useEffect } from 'react';

const Preloader = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    // console.log('isLoading:', isLoading); // Check isLoading value for debugging

    return (
        <div className={`preloader ${isLoading ? 'visible' : 'd-none'}`}>
            <div className="lds-ellipsis">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
};

export default Preloader;
