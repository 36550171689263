import React, { useEffect, useState } from 'react';
import heroline from "../assets/images/hero-line.png";
import Slider from 'react-slick';
import { getapi } from '../Api/Api';
import { BaseURL } from '../Api/BaseUrl';

function Testimonial() {
    const [data, setData] = useState([]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: data.length > 3 ? 3 : data.length,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,

        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            }
        ]
    };

    const handleGet = async () => {
        let res = await getapi('review');
        setData(res.data.data);
    };

    useEffect(() => {
        handleGet();
    }, []);

    return (
        <>
            {data.length > 0 && (
                <section className="testimonial-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-9">
                                <div className="section-title section-title-2 text-center">
                                    <img loading="lazy" src={heroline} className='img-fluid' alt="" />
                                    <h3 className="title">Testimonial</h3>
                                    <p>Our focus is on delivering you the absolute best support guiding you through training and providing.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Slider {...settings}>
                                    {data.map((item) => (
                                        <div className="testimonial-wrapper" key={item.id}>
                                            <div className="testimonial-item mt-30" style={{ margin: "15px" }}>
                                                <div className="quote">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="53.729" height="40" viewBox="0 0 53.729 40">
                                                        <g data-name="Group 21" transform="translate(0 0)">
                                                            <path data-name="Union 1" d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z" transform="translate(0 0)" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <p>{item.detail}</p>
                                                <div className="info">
                                                    <img loading="lazy" src={`${BaseURL}${item.image}`} alt="" />
                                                    <h5 className="title">{item.title}</h5>
                                                    <span>{item.position}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default Testimonial;
