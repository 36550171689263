import React from 'react'
import { Link } from 'react-router-dom'
import { BaseURL } from '../Api/BaseUrl'
import moment from 'moment/moment'


function BlogsCard(props) {


    return (
        <>
            {props.data && props.data.slice(0, 3).map((item, index) => {
                return (
                    <div className="col-lg-4 col-md-6 col-sm-9" key={item._id}>
                        <div className="article-item mt-30">
                            <div className="article-top text-center">
                                <Link to={`/article-detail/${item.url}`}><span>{item.title}</span></Link>
                            </div>
                            <div className="article-thumb">
                                <Link to={`/article-detail/${item.url}`}><img loading="lazy" src={`${BaseURL}${item.image}`} style={{ height: "200px" }} alt="" /></Link>
                                <div className="date">
                                    <h5 className="title">{moment(item.publish_date).format("MMMM-DD-YYYY")}</h5>
                                </div>
                            </div>
                            <div className="article-content pl-25 pr-25 pt-25">
                                <p>{item.short_detail}</p>
                                <Link to={`/article-detail/${item.url}`}>READ MORE</Link>
                            </div>
                        </div>
                    </div>
                );
            })}

            {props.data.length > 3 && <div class="col-lg-12"><div class="portfolio-btn text-center mt-40"><Link class="main-btn main-btn-3" to="/allarticles">ALL Articles</Link></div></div>}

        </>
    )
}

export default BlogsCard
